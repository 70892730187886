import {
  getGlobalParamsOptionsAsync,
  getParamsOptionsAsync
} from '@/utils/common'
export const baseViewFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.ota-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'author',
      type: 'input',
      label: 'file.author',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'company',
      type: 'input',
      label: 'file.company',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantWhitelist',
      type: 'treeSelect',
      label: 'file.tenant-white-list',
      isResolveGlobalParams: true,
      otherOptions: {
        disabled: true
      },
      placeholder: ' ',
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'modelId',
      type: 'select',
      label: 'file.model-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalModel', {
          isNeedPreFix: false
        }),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',

      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'iconUrl',
      type: 'imgUpload',
      label: 'common.app-icon',
      otherOptions: {
        disabled: true
      }
    }
  ]
}
