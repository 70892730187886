import { basicRequiredRule } from '@/template/rule'
import {
  getGlobalParamsOptionsAsync,
  getParamsOptionsAsync
} from '@/utils/common'

export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.ota-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'author',
      type: 'input',
      label: 'file.author',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'company',
      type: 'input',
      label: 'file.company',
      otherOptions: {
        maxlength: 200
      }
    },
    {
      field: 'tenantWhitelist',
      type: 'treeSelect',
      label: 'file.tenant-white-list',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'modelId',
      type: 'select',
      label: 'file.model-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalModel', {
          isNeedPreFix: false
        })
    },

    {
      field: 'description',
      type: 'input',
      label: 'general.description',

      otherOptions: {
        maxlength: 500
      }
    },
    {
      field: 'iconUrl',
      type: 'imgUpload',
      label: 'common.app-icon',
      uploadTip: 'file.icon-upload-tip',
      otherOptions: {
        uploadApiAction: 'system/uploadImgFile',
        uploadTip: 'Please upload Icon',
        limitTip: '(The Icon should not exceed 2M)'
      }
    }
  ],
  validateRules: {
    // name: [basicRequiredRule],
    author: [basicRequiredRule],
    company: [basicRequiredRule],
    modelId: [basicRequiredRule]
  }
}
